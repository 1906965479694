import React, { useRef } from 'react';
import _get from 'lodash/get';
import _chunk from 'lodash/chunk';
import _grouBy from 'lodash/groupBy';

import { TArticleList } from '@pages/news';
import DefaultArticles from './DefaultArticles';
import RestOfArticles from './RestOfArticles';

import { filterIndividualCategory } from '@utils/formatters';
import useArticleLoadMore from '@hooks/useArticleListLoadMore';
import { ARTICLES_LIMIT } from '@utils/constants';

interface IArticlesListProps {
  articleData: TArticleList[];
  category: string;
}

const ArticlesList: React.FC<IArticlesListProps> = ({ articleData, category, articleCategory, setArticleCategory }) => {
  const wrapperRef = useRef(category);
  const { limit, loadMore, handleLoadMore } = useArticleLoadMore(wrapperRef, category);
  const individualCategory = filterIndividualCategory(articleData, category);

  return (
    <>
      {category === 'Oak Tyres' ? (
        <DefaultArticles
          data={articleData}
          loadMore={loadMore}
          limit={limit}
          articleCategory={articleCategory} setArticleCategory={cat => { setArticleCategory(cat); }}
        />
      ) : (
        <RestOfArticles data={articleData} loadMore={loadMore} category={category} limit={limit} articleCategory={articleCategory} setArticleCategory={cat => { setArticleCategory(cat); }} />
      )}
      <div className="news__button-wrap">
        {category === 'Oak Tyres'
          ? limit < articleData.length && (
              <button className="news__button" onClick={handleLoadMore}>Load more</button>
            )
          : limit < individualCategory.length && (
              <button className="news__button" onClick={handleLoadMore}>Load More </button>
            )}
      </div>
    </>
  );
};

export default ArticlesList;
