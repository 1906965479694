import React from 'react';
import _get from 'lodash/get';

import { TArticleList } from '@pages/news';
import ArticleStructure from '../ArticleStructure';

interface IDefaultArticlesProps {  
  data: TArticleList[];
  loadMore: boolean;
  limit: number;
}

const DefaultArticles: React.FC<IDefaultArticlesProps> = ({  
  data,
  loadMore,
  limit,
  articleCategory,
  setArticleCategory,
}) => {
  return (
    <>
      <ArticleStructure data={data} limit={limit} articleCategory={articleCategory} setArticleCategory={cat => { setArticleCategory(cat); }} />      
    </>
  );
};

export default DefaultArticles;
