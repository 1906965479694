import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import classNames from 'classnames';
import _get from 'lodash/get';
import { gsap, Power3 } from 'gsap';

import Layout from '@components/Layout';
import ArticlesList from '@components/ArticlesList';

import {
  TPrismicLink,
  ENUM_STATIC_MENU_LINKS,
  TArticleContent,
  TPrismicSEO,
} from '@utils/types';
import { extractGraphqlData } from '@utils/extractors';
import useArticleListFilter from '@hooks/useArticleListFilter';

export type TArticleList = {
  node: {
    _meta: {
      uid: string;
    };
    title: string[];
    publication_date: string;
    category: string;
    image_list: {
      url: string;
      alt: string;
    };
    link: TPrismicLink;
    link_between_articles: TPrismicLink;
    seo: TPrismicSEO;

    article_content?: TArticleContent[];
  };
};

interface INewsProps {
  data: {
    prismic: {
      news_articles: {
        edges: TArticleList[];
      };
      news_page: {
        edges: any;
      };
    };
  };
}

let animButton = false;

const offsetTop = (el) => {
  if(!el) return 0;
  const rect = el.getBoundingClientRect();
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return (rect.top + scrollTop);
}

const runAnimations = () => {
  const tlNews = gsap.timeline();

  tlNews.fromTo('.news__title', {
    transform: "translate(-50%, 0)",
  }, {
    transform: "translate(0%, 0)",
    ease: Power3.easeInOut,
    duration: 1,
  });

  tlNews.fromTo('.news__title', {
    opacity: 0,
  }, {
    opacity: 1,
    ease: Power3.easeIn,
    duration: 1,
  }, '-=1');

  tlNews.fromTo('.news__n-filter-layout', {
    transform: "translate(-50%, 0)",
  }, {
    transform: "translate(0%, 0)",
    ease: Power3.easeInOut,
    duration: 1,
  }, '+=1');

  tlNews.fromTo('.news__n-filter-layout', {
    opacity: 0,
  }, {
    opacity: 1,
    ease: Power3.easeIn,
    duration: 1,
  }, '-=1');
};

const News: React.FC<INewsProps> = ({ data }) => {
  const doc = extractGraphqlData(data, 'news_articles');
  if (!doc) return null;

  const article_list: TArticleList[] = _get(data, ['prismic', 'news_articles', 'edges']);
  const news_page_seo = _get(data, ['prismic', 'news_page', 'edges']);
  const {
    page_title,
    page_description,
    canonical_tag,
    page_og_image,
  } = news_page_seo[0].node.seo[0];
  const { displayCategory, categories, result, filterActive, dispatch } = useArticleListFilter(
    article_list
  );

  const [pageAnimations, setPageAnimations] = useState(false);
  const [articleCategory, setArticleCategory] = useState(false);

  const updateAnimations = () => {
    const anchorPosition = offsetTop(document.querySelector('.anchor-show-bottom'));

    if(animButton === false) {
      const element = document.querySelector('.news__button');

      const tlBtn = gsap.timeline();

      tlBtn.set(element, {
        opacity: 0,
        transform: "translate(-50%, 0)",
      })

      if(offsetTop(element) <= anchorPosition) {
        animButton = true;

        tlBtn.fromTo(element, {
          transform: "translate(-50%, 0)",
        }, {
          transform: "translate(0%, 0)",
          ease: Power3.easeInOut,
          duration: 1,
        });

        tlBtn.fromTo(element, {
          opacity: 0,
        }, {
          opacity: 1,
          ease: Power3.easeIn,
          duration: 1,
        }, "-=1");
      }
    }
  };

  useEffect(() => {
    if(typeof window !== "undefined") {
      if(pageAnimations === false) {
        animButton = false;
        setPageAnimations(true);
        runAnimations();
      }

      updateAnimations();
    }

    window.addEventListener('scroll', () => { updateAnimations() });

    return () => {
      if(typeof window !== "undefined") {
        window.removeEventListener('scroll', () => { updateAnimations() });
      }
    };
  });

  return (
    <>
      <Layout
        title={page_title ? RichText.asText(page_title) : 'News'}
        description={page_description && RichText.asText(page_description)}
        firstLevelPage
        seo={{
          path: ENUM_STATIC_MENU_LINKS.NEWS,
          canonical: canonical_tag && canonical_tag.url,
          image: page_og_image ? page_og_image : null,
        }}
      >
        <div className="news">
          <div className="news__content">
            <div className="news__header">
              <h1 className="news__title">News</h1>

              <div className="news__n-filter-wrap">
                <div className="news__n-filter-layout">
                  <div
                    className={classNames('news__n-filter', {
                      'news__n-filter--all': Object.values(filterActive).every(k => !k),
                    })}
                  >
                    {categories.map((category: any) => (
                      <div key={category} className="news__n-filter-item-help">
                        <div
                          onClick={() => {
                            dispatch({ type: 'FILTER', category });
                          }}
                          className={classNames('news__n-filter-item', {
                            'news__n-filter-item--active': filterActive[category],
                          })}
                        >
                          <span className="news__n-filter-item-inner">{category}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <ArticlesList category={displayCategory} articleData={result} articleCategory={articleCategory} setArticleCategory={cat => { setArticleCategory(cat); }} />
          </div>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query News {
    prismic {
      news_page: allNews_pages {
        edges {
          node {
            seo {
              page_title
              page_description
              page_og_image
              canonical_tag {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
      news_articles: allNews_articles(sortBy: publication_date_DESC) {
        edges {
          node {
            title
            publication_date
            category
            featured
            link {
              ... on PRISMIC_News_article {
                _meta {
                  uid
                  type
                }
              }
            }
            image_list
            seo {
              page_title
              page_description
              page_og_image
              canonical_tag {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
            _meta {
              type
              uid
            }
          }
        }
      }
    }
  }
`;

export default News;
