import { useState, useEffect } from 'react';

import { ARTICLES_LIMIT } from '@utils/constants';

const useArticleLoadMore = (wrapperRef: React.MutableRefObject<string>, category: string) => {
  const [limit, setLimit] = useState(ARTICLES_LIMIT);
  const [loadMore, setLoadMore] = useState(false);

  useEffect(() => {
    if (wrapperRef.current !== category) {
      setLoadMore(false);
      setLimit(ARTICLES_LIMIT);
    }
    wrapperRef.current = category;
  }, [category]);

  function handleLoadMore() {
    setLoadMore(true);
    setLimit(limit + ARTICLES_LIMIT);
  }

  return {
    limit,
    loadMore,
    handleLoadMore,
  };
};

export default useArticleLoadMore;
