import { useReducer } from 'react';

import { TArticleList } from '@pages/news';
import { ARTICLE_DEFAULT_VALUES_ORDER } from '@utils/constants';

type TActiveFilter = {
  [key in string]: boolean;
};

const useArticleListFilter = (data: TArticleList[]) => {
  const filterCategories = ARTICLE_DEFAULT_VALUES_ORDER.map(prod => prod).filter(
    (p: string, index: number, array: string[]) => array.indexOf(p) === index
  );

  filterCategories.unshift('Oak Tyres');

  function handleFilter(category: string) {
    const defaultFilter: TActiveFilter = {
      'Oak Tyres': false,
      Davanti: false,
      Landsail: false,
      Evergreen: false,
    };

    category === 'Oak Tyres'
      ? Object.keys(defaultFilter).forEach(key => (defaultFilter[key] = true))
      : (defaultFilter[category] = true);

    return defaultFilter;
  }

  function reducer(state: any, action: any) {
    switch (action.type) {
      case 'FILTER':
        return {
          ...state,
          displayCategory: action.category,
          filterActive: handleFilter(action.category),
        };

      default:
        return state;
    }
  }
  const [{ displayCategory, categories, result, filterActive }, dispatch] = useReducer(reducer, {
    displayCategory: 'Oak Tyres',
    categories: filterCategories,
    result: data,
    filterActive: { 'Oak Tyres': false, Davanti: false, Landsail: false, Evergreen: false },
  });

  return {
    displayCategory,
    categories,
    result,
    filterActive,
    dispatch,
  };
};

export default useArticleListFilter;
