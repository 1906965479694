import React from 'react';

import ArticleStructure from '../ArticleStructure';

import { TArticleList } from '@pages/news';

interface IRestOfArticlesProps {
  data: TArticleList[];
  loadMore: boolean;
  category: string;
  limit: number;
}

const RestOfArticles: React.FC<IRestOfArticlesProps> = ({ data, category, limit, articleCategory, setArticleCategory }) => {
  return (
    <>
      <ArticleStructure data={data} limit={limit} category={category} filter articleCategory={articleCategory} setArticleCategory={cat => { setArticleCategory(cat); }} />
    </>
  );
};

export default RestOfArticles;
