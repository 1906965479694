import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import _get from 'lodash/get';
import { gsap, Power3, Power4 } from 'gsap';

import Images from '@components/Images';

import { TArticleList } from '@pages/news';
import { formatDate } from '@utils/formatters';
import { getLinkOrDefault } from '@utils/Prismic/getLinkOrDefault';
import { linkResolver } from '@utils/Prismic/linkResolver';
import { ARTICLES_LIMIT, FEATURED_TEXT } from '@utils/constants';

interface IArticleStructureProps {
  data: TArticleList[];
  limit: number;

  category?: string;
  filter?: boolean;
  sort?: boolean;
}

let changed = false;

let animated_news = 0;
let news = [];
let news_blocks = [];

const offsetTop = (el) => {
  if(!el) return 0;
  const rect = el.getBoundingClientRect();
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return (rect.top + scrollTop);
}

const newsAnimation = () => {
  const tlNews = gsap.timeline({
    delay: 1,
  });

  tlNews.set('.anim-news-image', {
    left: '-100%',
  });

  tlNews.set('.anim-news__block', {
    opacity: 0,
    transform: 'translate(-50%, 0)',
  });

  tlNews.fromTo('.anim-news-image', {
    left: "-100%",
  }, {
    left: 0,
    ease: Power4.easeInOut,
    duration: 0.7,
  });

  tlNews.to('.anim-news-image__block', {
    keyframes: [
      {left: '0%', ease: Power4.easeInOut, duration: 0.7},
      {left: '100%', ease: Power4.easeInOut, duration: 0.7},
      {left: '-100%', duration: 0}
    ]
  }, "-=0.7");

  tlNews.fromTo('.anim-news__block', {
    transform: "translate(-50%, 0)",
  }, {
    transform: "translate(0%, 0)",
    ease: Power3.easeInOut,
    duration: 1,
  }, "-=0.5");

  tlNews.fromTo('.anim-news__block', {
    opacity: 0,
  }, {
    opacity: 1,
    ease: Power3.easeIn,
    duration: 1,
  }, "-=1");
};

const updateNews = (delay) => {
  const anchorPosition = offsetTop(document.querySelector('.anchor-show-block'));
  let toRemove = [];

  if(typeof delay === 'undefined') delay = 0;

  news.forEach((element, index) => {
    if(element.classList.contains('hidden')) {
      const tlNews = gsap.timeline({
        delay: delay,
      });

      tlNews.set(element.querySelector('.anim-news-image'), {
        left: '-100%',
      });

      tlNews.set(element.querySelector('.anim-news__block'), {
        opacity: 0,
        transform: 'translate(-50%, 0)',
      });

      if(offsetTop(element) <= anchorPosition) {
        toRemove.push(index);
        animated_news += 1;

        tlNews.fromTo(element.querySelector('.anim-news-image'), {
          left: "-100%",
        }, {
          left: 0,
          ease: Power4.easeInOut,
          duration: 0.7,
        });

        tlNews.to(element.querySelector('.anim-news-image__block'), {
          keyframes: [
            {left: '0%', ease: Power4.easeInOut, duration: 0.7},
            {left: '100%', ease: Power4.easeInOut, duration: 0.7},
            {left: '-100%', duration: 0}
          ]
        }, "-=0.7");

        tlNews.fromTo(element.querySelector('.anim-news__block'), {
          transform: "translate(-50%, 0)",
        }, {
          transform: "translate(0%, 0)",
          ease: Power3.easeInOut,
          duration: 1,
        }, "-=0.5");

        tlNews.fromTo(element.querySelector('.anim-news__block'), {
          opacity: 0,
        }, {
          opacity: 1,
          ease: Power3.easeIn,
          duration: 1,
        }, "-=1");

        tlNews.eventCallback('onComplete', (element) => {
          element.classList.remove('hidden');
        }, [element]);
      }
    }
  });

  toRemove.sort().reverse();

  toRemove.forEach((el) => {
    news.splice(el, 1);
  });
};

const ArticleStructure: React.FC<IArticleStructureProps> = ({
  data,
  limit,  
  category,
  filter,
  articleCategory,
  setArticleCategory,
}) => {

  let parsedData = filter
    ? data.filter(prod => category === prod.node.category)
    : data;

  // apply featured ordering
  parsedData.sort((a,b)=>{
    if(a.node.featured && b.node.featured) {
      return 0;
    } else if(a.node.featured) {
      return -1;      
    } else if(b.node.featured) {
      return 1;
    } else {
      return 0;
    }
  });

  parsedData = parsedData.slice(0, limit);

  const initNewsAnimation = () => {
    let delay = 0.7;

    if(articleCategory !== category || news_blocks.length != parsedData.length) {
      news_blocks = Array.prototype.slice.call(document.querySelectorAll('.anim-news'));

      news = [];

      if(articleCategory !== category) {
        setArticleCategory(category);
        animated_news = 0;
      }

      for(let index = 0; index < news_blocks.length; index += 1) {
        if((index + 1) <= animated_news) {
          news_blocks[index].classList.remove('hidden');
        } else {
          news_blocks[index].classList.add('hidden');
        }

        news.push(news_blocks[index]);
      }
    }

    updateNews(delay);
  };

  useEffect(() => {
    if(typeof window !== 'undefined') {
      initNewsAnimation();
      window.addEventListener('scroll', () => {updateNews();});
    }

    return () => {
      if(typeof window !== 'undefined') {
        window.removeEventListener('scroll', () => {updateNews();});
      }
    };
  });

  return (
    <>
      <ul className="news__list">
        {parsedData.map(
          ({ node: { title, image_list, category, publication_date, link, featured, _meta } }, index) => {
            return (
              <li key={_meta.uid} className="news__item hidden anim-news">
                <Link className="news__item-link" to={linkResolver(getLinkOrDefault(link, _meta))} onClick={()=>{setArticleCategory(false)}}>
                  <div className="news__item-image">
                    <Images
                      imageData={[{'image': image_list}]}
                      extractPath="image"
                      alt={_get(image_list, ['alt'])}
                      classes={{
                        base: 'anim-news-image',
                        extend: '',
                        wrap: 'news__item-img',
                        class: ''
                      }}
                      ar="480:260"
                      width="544"
                      setHeight={false}
                    />
                  </div>
                  <div className="anim-news__block">
                    <h2 className="news__item-title">{RichText.asText(title)}</h2>
                    <div className="news__item-info">
                      <span className="news__item-category">{category}</span>
                      <span className="news__item-divider">/</span>
                      <span className={featured ? "news__item-date featured" : "news__item-date"} >
                        {featured ? FEATURED_TEXT : formatDate(publication_date)}
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
            );
          }
        )}
      </ul>
    </>
  );
};

export default ArticleStructure;
